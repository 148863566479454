import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { API_BASE_PATH } from 'projects/cedar-shared/src/interceptors/API_BASE_PATH'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ReleaseVersionInterceptor implements HttpInterceptor {
  //private readonly storageKey = 'cedar-public-release-version-id'
  private readonly apiHeaderName = 'X-Release-Version-Id'

  private releaseVersionId: number | null = null

  public get isReleaseVersionSpecified(): boolean {
    return this.releaseVersionId != null
  }

  constructor(@Inject(API_BASE_PATH) private apiBasePath: string) {
    // var value = localStorage.getItem(this.storageKey)
    // const previous = Number(value) || null
    // if (previous) {
    //   this.releaseVersionId = previous;
    // }
    // else this.clear()
  }

  get(): number {
    if (this.releaseVersionId == null) throw new Error('Invalid operation')
    return this.releaseVersionId
  }

  set(value: number): boolean {
    this.releaseVersionId = value
    //localStorage.setItem(this.storageKey, value.toString())
    return true
  }

  clear() {
    this.releaseVersionId = null
    //localStorage.removeItem(this.storageKey)
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var request = req
    if (req.url.startsWith(this.apiBasePath) && this.isReleaseVersionSpecified) {
      request = req.clone({
        headers: req.headers.append(this.apiHeaderName, this.releaseVersionId!.toString())
      })
    }

    return next.handle(request)
  }
}
